import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as navigationStyles from './MainNavigation.module.scss';
import HeaderSearchForm from '../Form/HeaderSearchForm';

export default function MainNavigation(): React.JSX.Element {
  const data = useStaticQuery(graphql`
    query listMainMenuLinks {
      allMenuItems(
        sort: { weight: ASC }
        filter: {
          menu_name: { eq: "main" }
          id: { glob: "menu-items-menu_link_content:*" }
          enabled: { eq: true }
          parent: { id: { eq: null } }
        }
      ) {
        nodes {
          id
          title
          url
          children {
            ... on MenuItems {
              title
              url
            }
          }
        }
      }
    }
  `);

  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };

  const [expandedMenuId, setExpandedMenuId] = useState<string | undefined>();
  const expandButtonHandler = (linkId: string) => {
    if (expandedMenuId === linkId) {
      setExpandedMenuId(undefined);
      return;
    }

    setExpandedMenuId(linkId);
  };

  return (
    <nav className={navigationStyles.navigation}>
      <div className="vl-layout">
        <div
          className={
            'vl-grid vl-grid--is-stacked-small vl-grid--align-space-between vl-grid--v-center ' +
            navigationStyles.layout
          }
        >
          <div
            className={
              'vl-col--9-12 vl-col--12-12--m ' + (!show ? 'vl-u-hidden--m' : '')
            }
          >
            <ul>
              {data.allMenuItems.nodes.map((link, idx) => {
                const isExpanded = expandedMenuId === link.id;
                return (
                  <li
                    key={idx}
                    onMouseEnter={() => setExpandedMenuId(link.id)}
                    onMouseLeave={() => setExpandedMenuId(undefined)}
                  >
                    <div className={navigationStyles.group}>
                      {!link.url || link.url === '' ? (
                        <div style={{ display: 'flex' }}>
                          <span className={navigationStyles.link}>
                            {link.title}
                          </span>
                          {link.children.length > 0 ? (
                            <button
                              onClick={() => expandButtonHandler(link.id)}
                              className="dropdown-btn"
                              aria-expanded={isExpanded}
                              aria-controls={`submenu_${link.id}`}
                            >
                              <span className="vl-u-visually-hidden">
                                Open submenu van {link.title}
                              </span>
                              <i
                                className={`vl-vi ${
                                  isExpanded ? 'vl-vi-nav-up' : 'vl-vi-nav-down'
                                }`}
                                aria-hidden="true"
                              ></i>
                            </button>
                          ) : null}
                        </div>
                      ) : (
                        <Link
                          className={
                            'header-links' + ' ' + navigationStyles.link
                          }
                          to={link.url}
                          onClick={() => setShow(false)}
                        >
                          {link.title}
                        </Link>
                      )}
                    </div>
                    <ul
                      id={`submenu_${link.id}`}
                      className={navigationStyles.submenu}
                      aria-hidden={!isExpanded}
                    >
                      {link.children.map((childLink, idy) => (
                        <li key={idy}>
                          <Link to={childLink.url}>{childLink.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="vl-col--3-12 vl-col--12-12--m">
            <div className="vl-grid vl-grid--v-center">
              <div className="vl-col--1-1 vl-col--6-12--m vl-col--3-12--s vl-u-hidden--l">
                <button
                  type="button"
                  className={navigationStyles.menu}
                  onClick={toggle}
                >
                  Menu
                </button>
              </div>
              <div className="vl-col--1-1 vl-col--6-12--m vl-col--9-12--s">
                <HeaderSearchForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
